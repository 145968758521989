import { default as energy_45green_45practicesoh56dfL1Q6Meta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journeyueMtiBQZycMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45builderax8DwQ9g69Meta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as index1PYzlE91YYMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45teamLiWbd3Acz7Meta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderlEp6PvOCMzMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resources8U6JyPsmcLMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_93vOf7xgBtddMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexUfNXrW6hbAMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexxvHiuGrVGxMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93e0z9bfIvBJMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexcIMRyLGOcgMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as central_45park_45special_45offerXuLvJgR6VtMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/central-park-special-offer.vue?macro=true";
import { default as ask_45a_45questionSxyZXT2Eb7Meta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiesRmLtXsSyRuMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faq472hD5UmrlMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourceshT8uclTF7GMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexfG8QAUn1JxMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationshlWAmjV6M3Meta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offerslKWR10qWYRMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexoiwpsFOtQZMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexjKB09DSnZRMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexsk5P11b83DMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as index9AmpCE4RkbMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as index4cKrjIvRWOMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as indexTAgImbZbgDMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as indexhDSIehh6eAMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as knolls_45hoa_45informationXdZDtJz5caMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue?macro=true";
import { default as land_45acquisition9Gt2KkgcN3Meta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placeonWITLiPkjMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenanceUVXKKrpsefMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_93oDSv92vkTKMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexClPxpwkHgfMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexjPk2LjUu96Meta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnessW7LZzSRDcJMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_93MkgBt27EkLMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favorites9P7VWi4XJEMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchpzQNxp9YAyMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhousePrcuw7wQFJMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededition4ZHF19cQYdMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottageijs32bQAelMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexQPRVeuFKxRMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodsYeDxhmofURMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45us1AuhPfGPOBMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacytermsAZzOHif65jMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandboxlIyn4ZN4orMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_93pxCQUQtOwrMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stubOBnK0gHNivMeta } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubOBnK0gHNiv } from "/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practicesoh56dfL1Q6Meta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practicesoh56dfL1Q6Meta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practicesoh56dfL1Q6Meta || {},
    alias: energy_45green_45practicesoh56dfL1Q6Meta?.alias || [],
    redirect: energy_45green_45practicesoh56dfL1Q6Meta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journeyueMtiBQZycMeta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journeyueMtiBQZycMeta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journeyueMtiBQZycMeta || {},
    alias: fun_45part_45journeyueMtiBQZycMeta?.alias || [],
    redirect: fun_45part_45journeyueMtiBQZycMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45builderax8DwQ9g69Meta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45builderax8DwQ9g69Meta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45builderax8DwQ9g69Meta || {},
    alias: homesafe_45colorado_45master_45builderax8DwQ9g69Meta?.alias || [],
    redirect: homesafe_45colorado_45master_45builderax8DwQ9g69Meta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: index1PYzlE91YYMeta?.name ?? "about-us",
    path: index1PYzlE91YYMeta?.path ?? "/about-us",
    meta: index1PYzlE91YYMeta || {},
    alias: index1PYzlE91YYMeta?.alias || [],
    redirect: index1PYzlE91YYMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45teamLiWbd3Acz7Meta?.name ?? "about-us-our-team",
    path: our_45teamLiWbd3Acz7Meta?.path ?? "/about-us/our-team",
    meta: our_45teamLiWbd3Acz7Meta || {},
    alias: our_45teamLiWbd3Acz7Meta?.alias || [],
    redirect: our_45teamLiWbd3Acz7Meta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderlEp6PvOCMzMeta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderlEp6PvOCMzMeta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderlEp6PvOCMzMeta || {},
    alias: preferred_45lenderlEp6PvOCMzMeta?.alias || [],
    redirect: preferred_45lenderlEp6PvOCMzMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resources8U6JyPsmcLMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resources8U6JyPsmcLMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resources8U6JyPsmcLMeta || {},
    alias: realtor_45resources8U6JyPsmcLMeta?.alias || [],
    redirect: realtor_45resources8U6JyPsmcLMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_93vOf7xgBtddMeta?.name ?? "blog-post",
    path: _91post_93vOf7xgBtddMeta?.path ?? "/blog/:post()",
    meta: _91post_93vOf7xgBtddMeta || {},
    alias: _91post_93vOf7xgBtddMeta?.alias || [],
    redirect: _91post_93vOf7xgBtddMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexUfNXrW6hbAMeta?.name ?? "blog-category-category",
    path: indexUfNXrW6hbAMeta?.path ?? "/blog/category/:category()",
    meta: indexUfNXrW6hbAMeta || {},
    alias: indexUfNXrW6hbAMeta?.alias || [],
    redirect: indexUfNXrW6hbAMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxvHiuGrVGxMeta?.name ?? "blog",
    path: indexxvHiuGrVGxMeta?.path ?? "/blog",
    meta: indexxvHiuGrVGxMeta || {},
    alias: indexxvHiuGrVGxMeta?.alias || [],
    redirect: indexxvHiuGrVGxMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93e0z9bfIvBJMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93e0z9bfIvBJMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93e0z9bfIvBJMeta || {},
    alias: _91_46_46_46slug_93e0z9bfIvBJMeta?.alias || [],
    redirect: _91_46_46_46slug_93e0z9bfIvBJMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexcIMRyLGOcgMeta?.name ?? "campaigns",
    path: indexcIMRyLGOcgMeta?.path ?? "/campaigns",
    meta: indexcIMRyLGOcgMeta || {},
    alias: indexcIMRyLGOcgMeta?.alias || [],
    redirect: indexcIMRyLGOcgMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: central_45park_45special_45offerXuLvJgR6VtMeta?.name ?? "central-park-special-offer",
    path: central_45park_45special_45offerXuLvJgR6VtMeta?.path ?? "/central-park-special-offer",
    meta: central_45park_45special_45offerXuLvJgR6VtMeta || {},
    alias: central_45park_45special_45offerXuLvJgR6VtMeta?.alias || [],
    redirect: central_45park_45special_45offerXuLvJgR6VtMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/central-park-special-offer.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionSxyZXT2Eb7Meta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionSxyZXT2Eb7Meta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionSxyZXT2Eb7Meta || {},
    alias: ask_45a_45questionSxyZXT2Eb7Meta?.alias || [],
    redirect: ask_45a_45questionSxyZXT2Eb7Meta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiesRmLtXsSyRuMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiesRmLtXsSyRuMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiesRmLtXsSyRuMeta || {},
    alias: career_45opportunitiesRmLtXsSyRuMeta?.alias || [],
    redirect: career_45opportunitiesRmLtXsSyRuMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faq472hD5UmrlMeta?.name ?? "contact-us-faq",
    path: faq472hD5UmrlMeta?.path ?? "/contact-us/faq",
    meta: faq472hD5UmrlMeta || {},
    alias: faq472hD5UmrlMeta?.alias || [],
    redirect: faq472hD5UmrlMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resourceshT8uclTF7GMeta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resourceshT8uclTF7GMeta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resourceshT8uclTF7GMeta || {},
    alias: homeowner_45resourceshT8uclTF7GMeta?.alias || [],
    redirect: homeowner_45resourceshT8uclTF7GMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexfG8QAUn1JxMeta?.name ?? "contact-us",
    path: indexfG8QAUn1JxMeta?.path ?? "/contact-us",
    meta: indexfG8QAUn1JxMeta || {},
    alias: indexfG8QAUn1JxMeta?.alias || [],
    redirect: indexfG8QAUn1JxMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locationshlWAmjV6M3Meta?.name ?? "contact-us-our-locations",
    path: our_45locationshlWAmjV6M3Meta?.path ?? "/contact-us/our-locations",
    meta: our_45locationshlWAmjV6M3Meta || {},
    alias: our_45locationshlWAmjV6M3Meta?.alias || [],
    redirect: our_45locationshlWAmjV6M3Meta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offerslKWR10qWYRMeta?.name ?? "current-offers",
    path: current_45offerslKWR10qWYRMeta?.path ?? "/current-offers",
    meta: current_45offerslKWR10qWYRMeta || {},
    alias: current_45offerslKWR10qWYRMeta?.alias || [],
    redirect: current_45offerslKWR10qWYRMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: indexoiwpsFOtQZMeta?.name ?? "events-event",
    path: indexoiwpsFOtQZMeta?.path ?? "/events/:event()",
    meta: indexoiwpsFOtQZMeta || {},
    alias: indexoiwpsFOtQZMeta?.alias || [],
    redirect: indexoiwpsFOtQZMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjKB09DSnZRMeta?.name ?? "events",
    path: indexjKB09DSnZRMeta?.path ?? "/events",
    meta: indexjKB09DSnZRMeta || {},
    alias: indexjKB09DSnZRMeta?.alias || [],
    redirect: indexjKB09DSnZRMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexsk5P11b83DMeta?.name ?? "homes-location-community",
    path: indexsk5P11b83DMeta?.path ?? "/homes/:location()/:community()",
    meta: indexsk5P11b83DMeta || {},
    alias: indexsk5P11b83DMeta?.alias || [],
    redirect: indexsk5P11b83DMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: index9AmpCE4RkbMeta?.name ?? "homes-location-community-models-model",
    path: index9AmpCE4RkbMeta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: index9AmpCE4RkbMeta || {},
    alias: index9AmpCE4RkbMeta?.alias || [],
    redirect: index9AmpCE4RkbMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: index4cKrjIvRWOMeta?.name ?? "homes-location-community-plans-plan",
    path: index4cKrjIvRWOMeta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: index4cKrjIvRWOMeta || {},
    alias: index4cKrjIvRWOMeta?.alias || [],
    redirect: index4cKrjIvRWOMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTAgImbZbgDMeta?.name ?? "homes-location-community-qmi-home",
    path: indexTAgImbZbgDMeta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: indexTAgImbZbgDMeta || {},
    alias: indexTAgImbZbgDMeta?.alias || [],
    redirect: indexTAgImbZbgDMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexhDSIehh6eAMeta?.name ?? "index",
    path: indexhDSIehh6eAMeta?.path ?? "/",
    meta: indexhDSIehh6eAMeta || {},
    alias: indexhDSIehh6eAMeta?.alias || [],
    redirect: indexhDSIehh6eAMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: knolls_45hoa_45informationXdZDtJz5caMeta?.name ?? "knolls-hoa-information",
    path: knolls_45hoa_45informationXdZDtJz5caMeta?.path ?? "/knolls-hoa-information",
    meta: knolls_45hoa_45informationXdZDtJz5caMeta || {},
    alias: knolls_45hoa_45informationXdZDtJz5caMeta?.alias || [],
    redirect: knolls_45hoa_45informationXdZDtJz5caMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisition9Gt2KkgcN3Meta?.name ?? "land-acquisition",
    path: land_45acquisition9Gt2KkgcN3Meta?.path ?? "/land-acquisition",
    meta: land_45acquisition9Gt2KkgcN3Meta || {},
    alias: land_45acquisition9Gt2KkgcN3Meta?.alias || [],
    redirect: land_45acquisition9Gt2KkgcN3Meta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placeonWITLiPkjMeta?.name ?? "lifestyle-brents-place",
    path: brents_45placeonWITLiPkjMeta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placeonWITLiPkjMeta || {},
    alias: brents_45placeonWITLiPkjMeta?.alias || [],
    redirect: brents_45placeonWITLiPkjMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenanceUVXKKrpsefMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenanceUVXKKrpsefMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenanceUVXKKrpsefMeta || {},
    alias: experience_45lower_45maintenanceUVXKKrpsefMeta?.alias || [],
    redirect: experience_45lower_45maintenanceUVXKKrpsefMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93oDSv92vkTKMeta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_93oDSv92vkTKMeta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_93oDSv92vkTKMeta || {},
    alias: _91testimonial_93oDSv92vkTKMeta?.alias || [],
    redirect: _91testimonial_93oDSv92vkTKMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: indexClPxpwkHgfMeta?.name ?? "lifestyle-reviews-category-category",
    path: indexClPxpwkHgfMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: indexClPxpwkHgfMeta || {},
    alias: indexClPxpwkHgfMeta?.alias || [],
    redirect: indexClPxpwkHgfMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjPk2LjUu96Meta?.name ?? "lifestyle-reviews",
    path: indexjPk2LjUu96Meta?.path ?? "/lifestyle/reviews",
    meta: indexjPk2LjUu96Meta || {},
    alias: indexjPk2LjUu96Meta?.alias || [],
    redirect: indexjPk2LjUu96Meta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnessW7LZzSRDcJMeta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnessW7LZzSRDcJMeta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnessW7LZzSRDcJMeta || {},
    alias: whats_45lifefullnessW7LZzSRDcJMeta?.alias || [],
    redirect: whats_45lifefullnessW7LZzSRDcJMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93MkgBt27EkLMeta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_93MkgBt27EkLMeta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_93MkgBt27EkLMeta || {},
    alias: _91_46_46_46slug_93MkgBt27EkLMeta?.alias || [],
    redirect: _91_46_46_46slug_93MkgBt27EkLMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favorites9P7VWi4XJEMeta?.name ?? "my-favorites",
    path: my_45favorites9P7VWi4XJEMeta?.path ?? "/my-favorites",
    meta: my_45favorites9P7VWi4XJEMeta || {},
    alias: my_45favorites9P7VWi4XJEMeta?.alias || [],
    redirect: my_45favorites9P7VWi4XJEMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45searchpzQNxp9YAyMeta?.name ?? "new-home-search",
    path: new_45home_45searchpzQNxp9YAyMeta?.path ?? "/new-home-search",
    meta: new_45home_45searchpzQNxp9YAyMeta || {},
    alias: new_45home_45searchpzQNxp9YAyMeta?.alias || [],
    redirect: new_45home_45searchpzQNxp9YAyMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhousePrcuw7wQFJMeta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhousePrcuw7wQFJMeta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhousePrcuw7wQFJMeta || {},
    alias: easyhousePrcuw7wQFJMeta?.alias || [],
    redirect: easyhousePrcuw7wQFJMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededition4ZHF19cQYdMeta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededition4ZHF19cQYdMeta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededition4ZHF19cQYdMeta || {},
    alias: limitededition4ZHF19cQYdMeta?.alias || [],
    redirect: limitededition4ZHF19cQYdMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottageijs32bQAelMeta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottageijs32bQAelMeta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottageijs32bQAelMeta || {},
    alias: wee_45cottageijs32bQAelMeta?.alias || [],
    redirect: wee_45cottageijs32bQAelMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: indexQPRVeuFKxRMeta?.name ?? "our-homes",
    path: indexQPRVeuFKxRMeta?.path ?? "/our-homes",
    meta: indexQPRVeuFKxRMeta || {},
    alias: indexQPRVeuFKxRMeta?.alias || [],
    redirect: indexQPRVeuFKxRMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodsYeDxhmofURMeta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodsYeDxhmofURMeta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodsYeDxhmofURMeta || {},
    alias: our_45neighborhoodsYeDxhmofURMeta?.alias || [],
    redirect: our_45neighborhoodsYeDxhmofURMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45us1AuhPfGPOBMeta?.name ?? "partner-with-us",
    path: partner_45with_45us1AuhPfGPOBMeta?.path ?? "/partner-with-us",
    meta: partner_45with_45us1AuhPfGPOBMeta || {},
    alias: partner_45with_45us1AuhPfGPOBMeta?.alias || [],
    redirect: partner_45with_45us1AuhPfGPOBMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacytermsAZzOHif65jMeta?.name ?? "privacyterms",
    path: privacytermsAZzOHif65jMeta?.path ?? "/privacyterms",
    meta: privacytermsAZzOHif65jMeta || {},
    alias: privacytermsAZzOHif65jMeta?.alias || [],
    redirect: privacytermsAZzOHif65jMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandboxlIyn4ZN4orMeta?.name ?? "sandbox",
    path: sandboxlIyn4ZN4orMeta?.path ?? "/sandbox",
    meta: sandboxlIyn4ZN4orMeta || {},
    alias: sandboxlIyn4ZN4orMeta?.alias || [],
    redirect: sandboxlIyn4ZN4orMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93pxCQUQtOwrMeta?.name ?? "videos-slug",
    path: _91_46_46_46slug_93pxCQUQtOwrMeta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_93pxCQUQtOwrMeta || {},
    alias: _91_46_46_46slug_93pxCQUQtOwrMeta?.alias || [],
    redirect: _91_46_46_46slug_93pxCQUQtOwrMeta?.redirect,
    component: () => import("/codebuild/output/src722073993/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/about-us/in-the-news/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/about-us/realtor-program/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/about-us/what-we-do/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/about-us/who-we-are/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/build-with-us/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/career-opportunities/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/email-signup/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/get-updates/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/get-updates/thank-you/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/how-to-video-library/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/lifestyle/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/locations/silver-leaf/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/login/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/lost-password/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/maintenance-items/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/organism-page/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/our-homes/home-collections/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/register/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/reset-password/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/style-guide/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  },
  {
    name: component_45stubOBnK0gHNivMeta?.name ?? undefined,
    path: component_45stubOBnK0gHNivMeta?.path ?? "/warranty-service-request/",
    meta: component_45stubOBnK0gHNivMeta || {},
    alias: component_45stubOBnK0gHNivMeta?.alias || [],
    redirect: component_45stubOBnK0gHNivMeta?.redirect,
    component: component_45stubOBnK0gHNiv
  }
]