import { default as energy_45green_45practicesMAZBJv2gSOMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journey80gCOuMzWkMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45builderFG1gaJOgT4Meta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as index6BSOjWwhYoMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45teamzVTKVRnaspMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderR7wYBQcHvQMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourcesBU158hep1qMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_93aTBQENzVYyMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as index28lBMBoiTjMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexLQSL77gQA2Meta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93VYOWAP8KMVMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as index4rxxwnzgZpMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as central_45park_45special_45offerIrVkJ0rBPyMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/central-park-special-offer.vue?macro=true";
import { default as ask_45a_45questionAPChe6KdvVMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunities7VTO4fQgOoMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqkyiFbETtzIMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcesMjUgFCFZitMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexN3Hjwb0ycCMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationsuRkfj6t0KtMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offersHBZg8RdwgmMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as index2uRrMsSL3gMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexOb5vkRzw3AMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as index38GRJv7HgIMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as indexAhbEdExFXeMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as index9Wj8CtDatuMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as index5VGYCCsdAoMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as index132AYzGIGeMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as knolls_45hoa_45informationYqwL0CSjCPMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue?macro=true";
import { default as land_45acquisitionZqCDPOgy9pMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placePc2JOuWeixMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenanceDh3Tnah42OMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_93xW9StabCOIMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as index0YwjlXdBHiMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexoglJwJ2I8lMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnessPs5lMOD6GDMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_93WCYSbz4OBIMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favoritesjUQV5vxRRkMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchLQpRO7hta0Meta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhouseVvQJmgj3yeMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededition3a6AJeU9kBMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottagezwffJvh5ifMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexf8GRzSsLi0Meta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodsUxYpoeZEz8Meta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45usx5pGyFmIngMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacytermsGTXUg7UYgJMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandboxm1JzSy88rqMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_932X83KP3zfkMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stubsEZIMOiHzUMeta } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubsEZIMOiHzU } from "/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practicesMAZBJv2gSOMeta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practicesMAZBJv2gSOMeta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practicesMAZBJv2gSOMeta || {},
    alias: energy_45green_45practicesMAZBJv2gSOMeta?.alias || [],
    redirect: energy_45green_45practicesMAZBJv2gSOMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journey80gCOuMzWkMeta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journey80gCOuMzWkMeta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journey80gCOuMzWkMeta || {},
    alias: fun_45part_45journey80gCOuMzWkMeta?.alias || [],
    redirect: fun_45part_45journey80gCOuMzWkMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45builderFG1gaJOgT4Meta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45builderFG1gaJOgT4Meta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45builderFG1gaJOgT4Meta || {},
    alias: homesafe_45colorado_45master_45builderFG1gaJOgT4Meta?.alias || [],
    redirect: homesafe_45colorado_45master_45builderFG1gaJOgT4Meta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: index6BSOjWwhYoMeta?.name ?? "about-us",
    path: index6BSOjWwhYoMeta?.path ?? "/about-us",
    meta: index6BSOjWwhYoMeta || {},
    alias: index6BSOjWwhYoMeta?.alias || [],
    redirect: index6BSOjWwhYoMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45teamzVTKVRnaspMeta?.name ?? "about-us-our-team",
    path: our_45teamzVTKVRnaspMeta?.path ?? "/about-us/our-team",
    meta: our_45teamzVTKVRnaspMeta || {},
    alias: our_45teamzVTKVRnaspMeta?.alias || [],
    redirect: our_45teamzVTKVRnaspMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderR7wYBQcHvQMeta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderR7wYBQcHvQMeta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderR7wYBQcHvQMeta || {},
    alias: preferred_45lenderR7wYBQcHvQMeta?.alias || [],
    redirect: preferred_45lenderR7wYBQcHvQMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourcesBU158hep1qMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourcesBU158hep1qMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourcesBU158hep1qMeta || {},
    alias: realtor_45resourcesBU158hep1qMeta?.alias || [],
    redirect: realtor_45resourcesBU158hep1qMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_93aTBQENzVYyMeta?.name ?? "blog-post",
    path: _91post_93aTBQENzVYyMeta?.path ?? "/blog/:post()",
    meta: _91post_93aTBQENzVYyMeta || {},
    alias: _91post_93aTBQENzVYyMeta?.alias || [],
    redirect: _91post_93aTBQENzVYyMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: index28lBMBoiTjMeta?.name ?? "blog-category-category",
    path: index28lBMBoiTjMeta?.path ?? "/blog/category/:category()",
    meta: index28lBMBoiTjMeta || {},
    alias: index28lBMBoiTjMeta?.alias || [],
    redirect: index28lBMBoiTjMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLQSL77gQA2Meta?.name ?? "blog",
    path: indexLQSL77gQA2Meta?.path ?? "/blog",
    meta: indexLQSL77gQA2Meta || {},
    alias: indexLQSL77gQA2Meta?.alias || [],
    redirect: indexLQSL77gQA2Meta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93VYOWAP8KMVMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93VYOWAP8KMVMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93VYOWAP8KMVMeta || {},
    alias: _91_46_46_46slug_93VYOWAP8KMVMeta?.alias || [],
    redirect: _91_46_46_46slug_93VYOWAP8KMVMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index4rxxwnzgZpMeta?.name ?? "campaigns",
    path: index4rxxwnzgZpMeta?.path ?? "/campaigns",
    meta: index4rxxwnzgZpMeta || {},
    alias: index4rxxwnzgZpMeta?.alias || [],
    redirect: index4rxxwnzgZpMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: central_45park_45special_45offerIrVkJ0rBPyMeta?.name ?? "central-park-special-offer",
    path: central_45park_45special_45offerIrVkJ0rBPyMeta?.path ?? "/central-park-special-offer",
    meta: central_45park_45special_45offerIrVkJ0rBPyMeta || {},
    alias: central_45park_45special_45offerIrVkJ0rBPyMeta?.alias || [],
    redirect: central_45park_45special_45offerIrVkJ0rBPyMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/central-park-special-offer.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionAPChe6KdvVMeta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionAPChe6KdvVMeta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionAPChe6KdvVMeta || {},
    alias: ask_45a_45questionAPChe6KdvVMeta?.alias || [],
    redirect: ask_45a_45questionAPChe6KdvVMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunities7VTO4fQgOoMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunities7VTO4fQgOoMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunities7VTO4fQgOoMeta || {},
    alias: career_45opportunities7VTO4fQgOoMeta?.alias || [],
    redirect: career_45opportunities7VTO4fQgOoMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faqkyiFbETtzIMeta?.name ?? "contact-us-faq",
    path: faqkyiFbETtzIMeta?.path ?? "/contact-us/faq",
    meta: faqkyiFbETtzIMeta || {},
    alias: faqkyiFbETtzIMeta?.alias || [],
    redirect: faqkyiFbETtzIMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resourcesMjUgFCFZitMeta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resourcesMjUgFCFZitMeta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resourcesMjUgFCFZitMeta || {},
    alias: homeowner_45resourcesMjUgFCFZitMeta?.alias || [],
    redirect: homeowner_45resourcesMjUgFCFZitMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexN3Hjwb0ycCMeta?.name ?? "contact-us",
    path: indexN3Hjwb0ycCMeta?.path ?? "/contact-us",
    meta: indexN3Hjwb0ycCMeta || {},
    alias: indexN3Hjwb0ycCMeta?.alias || [],
    redirect: indexN3Hjwb0ycCMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locationsuRkfj6t0KtMeta?.name ?? "contact-us-our-locations",
    path: our_45locationsuRkfj6t0KtMeta?.path ?? "/contact-us/our-locations",
    meta: our_45locationsuRkfj6t0KtMeta || {},
    alias: our_45locationsuRkfj6t0KtMeta?.alias || [],
    redirect: our_45locationsuRkfj6t0KtMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offersHBZg8RdwgmMeta?.name ?? "current-offers",
    path: current_45offersHBZg8RdwgmMeta?.path ?? "/current-offers",
    meta: current_45offersHBZg8RdwgmMeta || {},
    alias: current_45offersHBZg8RdwgmMeta?.alias || [],
    redirect: current_45offersHBZg8RdwgmMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: index2uRrMsSL3gMeta?.name ?? "events-event",
    path: index2uRrMsSL3gMeta?.path ?? "/events/:event()",
    meta: index2uRrMsSL3gMeta || {},
    alias: index2uRrMsSL3gMeta?.alias || [],
    redirect: index2uRrMsSL3gMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOb5vkRzw3AMeta?.name ?? "events",
    path: indexOb5vkRzw3AMeta?.path ?? "/events",
    meta: indexOb5vkRzw3AMeta || {},
    alias: indexOb5vkRzw3AMeta?.alias || [],
    redirect: indexOb5vkRzw3AMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index38GRJv7HgIMeta?.name ?? "homes-location-community",
    path: index38GRJv7HgIMeta?.path ?? "/homes/:location()/:community()",
    meta: index38GRJv7HgIMeta || {},
    alias: index38GRJv7HgIMeta?.alias || [],
    redirect: index38GRJv7HgIMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAhbEdExFXeMeta?.name ?? "homes-location-community-models-model",
    path: indexAhbEdExFXeMeta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: indexAhbEdExFXeMeta || {},
    alias: indexAhbEdExFXeMeta?.alias || [],
    redirect: indexAhbEdExFXeMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: index9Wj8CtDatuMeta?.name ?? "homes-location-community-plans-plan",
    path: index9Wj8CtDatuMeta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: index9Wj8CtDatuMeta || {},
    alias: index9Wj8CtDatuMeta?.alias || [],
    redirect: index9Wj8CtDatuMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: index5VGYCCsdAoMeta?.name ?? "homes-location-community-qmi-home",
    path: index5VGYCCsdAoMeta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: index5VGYCCsdAoMeta || {},
    alias: index5VGYCCsdAoMeta?.alias || [],
    redirect: index5VGYCCsdAoMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: index132AYzGIGeMeta?.name ?? "index",
    path: index132AYzGIGeMeta?.path ?? "/",
    meta: index132AYzGIGeMeta || {},
    alias: index132AYzGIGeMeta?.alias || [],
    redirect: index132AYzGIGeMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: knolls_45hoa_45informationYqwL0CSjCPMeta?.name ?? "knolls-hoa-information",
    path: knolls_45hoa_45informationYqwL0CSjCPMeta?.path ?? "/knolls-hoa-information",
    meta: knolls_45hoa_45informationYqwL0CSjCPMeta || {},
    alias: knolls_45hoa_45informationYqwL0CSjCPMeta?.alias || [],
    redirect: knolls_45hoa_45informationYqwL0CSjCPMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitionZqCDPOgy9pMeta?.name ?? "land-acquisition",
    path: land_45acquisitionZqCDPOgy9pMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitionZqCDPOgy9pMeta || {},
    alias: land_45acquisitionZqCDPOgy9pMeta?.alias || [],
    redirect: land_45acquisitionZqCDPOgy9pMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placePc2JOuWeixMeta?.name ?? "lifestyle-brents-place",
    path: brents_45placePc2JOuWeixMeta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placePc2JOuWeixMeta || {},
    alias: brents_45placePc2JOuWeixMeta?.alias || [],
    redirect: brents_45placePc2JOuWeixMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenanceDh3Tnah42OMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenanceDh3Tnah42OMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenanceDh3Tnah42OMeta || {},
    alias: experience_45lower_45maintenanceDh3Tnah42OMeta?.alias || [],
    redirect: experience_45lower_45maintenanceDh3Tnah42OMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93xW9StabCOIMeta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_93xW9StabCOIMeta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_93xW9StabCOIMeta || {},
    alias: _91testimonial_93xW9StabCOIMeta?.alias || [],
    redirect: _91testimonial_93xW9StabCOIMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: index0YwjlXdBHiMeta?.name ?? "lifestyle-reviews-category-category",
    path: index0YwjlXdBHiMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: index0YwjlXdBHiMeta || {},
    alias: index0YwjlXdBHiMeta?.alias || [],
    redirect: index0YwjlXdBHiMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoglJwJ2I8lMeta?.name ?? "lifestyle-reviews",
    path: indexoglJwJ2I8lMeta?.path ?? "/lifestyle/reviews",
    meta: indexoglJwJ2I8lMeta || {},
    alias: indexoglJwJ2I8lMeta?.alias || [],
    redirect: indexoglJwJ2I8lMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnessPs5lMOD6GDMeta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnessPs5lMOD6GDMeta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnessPs5lMOD6GDMeta || {},
    alias: whats_45lifefullnessPs5lMOD6GDMeta?.alias || [],
    redirect: whats_45lifefullnessPs5lMOD6GDMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93WCYSbz4OBIMeta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_93WCYSbz4OBIMeta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_93WCYSbz4OBIMeta || {},
    alias: _91_46_46_46slug_93WCYSbz4OBIMeta?.alias || [],
    redirect: _91_46_46_46slug_93WCYSbz4OBIMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesjUQV5vxRRkMeta?.name ?? "my-favorites",
    path: my_45favoritesjUQV5vxRRkMeta?.path ?? "/my-favorites",
    meta: my_45favoritesjUQV5vxRRkMeta || {},
    alias: my_45favoritesjUQV5vxRRkMeta?.alias || [],
    redirect: my_45favoritesjUQV5vxRRkMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45searchLQpRO7hta0Meta?.name ?? "new-home-search",
    path: new_45home_45searchLQpRO7hta0Meta?.path ?? "/new-home-search",
    meta: new_45home_45searchLQpRO7hta0Meta || {},
    alias: new_45home_45searchLQpRO7hta0Meta?.alias || [],
    redirect: new_45home_45searchLQpRO7hta0Meta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhouseVvQJmgj3yeMeta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhouseVvQJmgj3yeMeta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhouseVvQJmgj3yeMeta || {},
    alias: easyhouseVvQJmgj3yeMeta?.alias || [],
    redirect: easyhouseVvQJmgj3yeMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededition3a6AJeU9kBMeta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededition3a6AJeU9kBMeta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededition3a6AJeU9kBMeta || {},
    alias: limitededition3a6AJeU9kBMeta?.alias || [],
    redirect: limitededition3a6AJeU9kBMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottagezwffJvh5ifMeta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottagezwffJvh5ifMeta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottagezwffJvh5ifMeta || {},
    alias: wee_45cottagezwffJvh5ifMeta?.alias || [],
    redirect: wee_45cottagezwffJvh5ifMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: indexf8GRzSsLi0Meta?.name ?? "our-homes",
    path: indexf8GRzSsLi0Meta?.path ?? "/our-homes",
    meta: indexf8GRzSsLi0Meta || {},
    alias: indexf8GRzSsLi0Meta?.alias || [],
    redirect: indexf8GRzSsLi0Meta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodsUxYpoeZEz8Meta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodsUxYpoeZEz8Meta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodsUxYpoeZEz8Meta || {},
    alias: our_45neighborhoodsUxYpoeZEz8Meta?.alias || [],
    redirect: our_45neighborhoodsUxYpoeZEz8Meta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45usx5pGyFmIngMeta?.name ?? "partner-with-us",
    path: partner_45with_45usx5pGyFmIngMeta?.path ?? "/partner-with-us",
    meta: partner_45with_45usx5pGyFmIngMeta || {},
    alias: partner_45with_45usx5pGyFmIngMeta?.alias || [],
    redirect: partner_45with_45usx5pGyFmIngMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacytermsGTXUg7UYgJMeta?.name ?? "privacyterms",
    path: privacytermsGTXUg7UYgJMeta?.path ?? "/privacyterms",
    meta: privacytermsGTXUg7UYgJMeta || {},
    alias: privacytermsGTXUg7UYgJMeta?.alias || [],
    redirect: privacytermsGTXUg7UYgJMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandboxm1JzSy88rqMeta?.name ?? "sandbox",
    path: sandboxm1JzSy88rqMeta?.path ?? "/sandbox",
    meta: sandboxm1JzSy88rqMeta || {},
    alias: sandboxm1JzSy88rqMeta?.alias || [],
    redirect: sandboxm1JzSy88rqMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_932X83KP3zfkMeta?.name ?? "videos-slug",
    path: _91_46_46_46slug_932X83KP3zfkMeta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_932X83KP3zfkMeta || {},
    alias: _91_46_46_46slug_932X83KP3zfkMeta?.alias || [],
    redirect: _91_46_46_46slug_932X83KP3zfkMeta?.redirect,
    component: () => import("/codebuild/output/src1752817636/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/about-us/in-the-news/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/about-us/realtor-program/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/about-us/what-we-do/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/about-us/who-we-are/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/build-with-us/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/career-opportunities/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/email-signup/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/get-updates/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/get-updates/thank-you/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/how-to-video-library/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/lifestyle/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/locations/silver-leaf/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/login/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/lost-password/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/maintenance-items/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/organism-page/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/our-homes/home-collections/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/register/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/reset-password/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/style-guide/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  },
  {
    name: component_45stubsEZIMOiHzUMeta?.name ?? undefined,
    path: component_45stubsEZIMOiHzUMeta?.path ?? "/warranty-service-request/",
    meta: component_45stubsEZIMOiHzUMeta || {},
    alias: component_45stubsEZIMOiHzUMeta?.alias || [],
    redirect: component_45stubsEZIMOiHzUMeta?.redirect,
    component: component_45stubsEZIMOiHzU
  }
]